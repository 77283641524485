// Redux Screenings module
// see https://github.com/erikras/ducks-modular-redux

import { Screenings } from '../../services/';
import _ from 'lodash';
import moment from 'moment';

const LOADING_SCREENINGS                  = 'kronos/screenings/LOADING_SCREENINGS';
const LOADING_SCREENINGS_SUCCESS          = 'kronos/screenings/LOADING_SCREENINGS_SUCCESS';
const LOADING_SCREENINGS_FAILURE          = 'kronos/screenings/LOADING_SCREENINGS_FAILURE';
const LOADING_SCREENING                   = 'kronos/screenings/LOADING_SCREENING';
const LOADING_SCREENING_SUCCESS           = 'kronos/screenings/LOADING_SCREENING_SUCCESS';
const LOADING_SCREENING_FAILURE           = 'kronos/screenings/LOADING_SCREENING_FAILURE';
const UPDATING_SCREENING                  = 'kronos/screenings/UPDATING_SCREENING';
const UPDATING_SCREENING_SUCCESS          = 'kronos/screenings/UPDATING_SCREENING_SUCCESS';
const UPDATING_SCREENING_FAILURE          = 'kronos/screenings/UPDATING_SCREENING_FAILURE';
const START_CREATING_SCREENING            = 'kronos/screenings/START_CREATING_SCREENING';
const CREATING_SCREENING                  = 'kronos/screenings/CREATING_SCREENING';
const CREATING_SCREENING_SUCCESS          = 'kronos/screenings/CREATING_SCREENING_SUCCESS';
const CREATING_SCREENING_FAILURE          = 'kronos/screenings/CREATING_SCREENING_FAILURE';
const DELETING_SCREENING                  = 'kronos/screenings/DELETING_SCREENING';
const DELETING_SCREENING_SUCCESS          = 'kronos/screenings/DELETING_SCREENING_SUCCESS';
const DELETING_SCREENING_FAILURE          = 'kronos/screenings/DELETING_SCREENING_FAILURE';
const ADDING_PRICING                      = 'kronos/screenings/ADDING_PRICING';
const REMOVING_PRICING                    = 'kronos/screenings/REMOVING_PRICING';
const TKTSYNCING_SCREENINGS               = 'kronos/screenings/TKTSYNCING_SCREENINGS';
const TKTSYNCING_SCREENINGS_SUCCESS       = 'kronos/screenings/TKTSYNCING_SCREENINGS_SUCCESS';
const TKTSYNCING_SCREENINGS_FAILURE       = 'kronos/screenings/TKTSYNCING_SCREENINGS_FAILURE';
const UPDATING_SCREENING_PROPERTY         = 'kronos/screenings/UPDATING_SCREENING_PROPERTY';
const UPDATING_SCREENING_PROPERTY_SUCCESS = 'kronos/screenings/UPDATING_SCREENING_PROPERTY_SUCCESS';
const UPDATING_SCREENING_PROPERTY_FAILURE = 'kronos/screenings/UPDATING_SCREENING_PROPERTY_FAILURE';
const UPDATING_SCREENING_PRICINGS         = 'kronos/tickettypes/UPDATING_TICKETTYPE_PRICINGS';
const UPDATING_SCREENING_PLACE            = 'kronos/tickettypes/UPDATING_TICKETTYPE_PLACE';
const UPDATING_FILTERS                    = 'kronos/screenings/UPDATING_FILTERS';

const initialState = {
    isLoading: false,
    hasLoadingError: false,
    loadingErrorMessage: '',
    screeningSuccessfullyCreated: false,
    screenings: [],
    screening: {},
    filters: {
        start_at_gte: moment().startOf('day'),
        places_ids: []
    },
    isTktsyncing: false,
    hasTktsyncingError: false,
    tktsyncingErrorMessage: '',
    imported: [],
}

const initialPricing = (names, currency, eshopWeight) => {
    return {
        name: names,
        price: { [currency] : 0 },
        value: { [currency] : 0 },
        value_per_screening: { [currency] : 0 },
        wallet_amount: { [currency] : 0 },
        VAT: 0,
        category: "default",
        sellers: ['eshop', 'on-site', 'admin'],
        opaque: {
            eshop_sort_weight: eshopWeight
        }
    }
}

// Reducer
export default function reducer(state = initialState, action) {
    switch (action.type) {
        case LOADING_SCREENINGS:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false,
                screeningSuccessfullyDeleted: false
            };
        case LOADING_SCREENINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                screenings: action.screenings
            };
        case LOADING_SCREENINGS_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case LOADING_SCREENING:
            return {
                ...state,
                isLoading: true,
                screeningSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case LOADING_SCREENING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                screening: action.screening
            };
        case LOADING_SCREENING_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_SCREENING:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_SCREENING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                screening: action.screening
            };
        case UPDATING_SCREENING_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case START_CREATING_SCREENING:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                screeningSuccessfullyCreated: false,
                screening: {}
            };
        case CREATING_SCREENING:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case CREATING_SCREENING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                screeningSuccessfullyCreated: true,
                screening: action.screening
            };
        case CREATING_SCREENING_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                screeningSuccessfullyCreated: false,
                loadingErrorMessage: action.error.message
            };
        case DELETING_SCREENING:
            return {
                ...state,
                isLoading: true,
                screeningSuccessfullyDeleted: false,
                hasLoadingError: false
            };
        case DELETING_SCREENING_SUCCESS:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                screeningSuccessfullyDeleted: true,
                screening: action.screening
            };
        case DELETING_SCREENING_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                screeningSuccessfullyDeleted: false,
                loadingErrorMessage: action.error.message
            };
        case ADDING_PRICING:
            return {
                ...state,
                screening: {
                    ...state.screening,
                    pricings: {
                        ...state.screening.pricings,
                        [Date.now()]: initialPricing(action.names, action.currency, Object.keys(state.screening.pricings).length + 1)
                    }
                }
            };
        case REMOVING_PRICING:
            const { [action._id]: toRemove, ...otherPricings } = state.screening.pricings;
            return {
                ...state,
                screening: {
                    ...state.screening,
                    pricings: otherPricings
                }
            };
        case TKTSYNCING_SCREENINGS:
            return {
                ...state,
                isTktsyncing: true,
                hasTktsyncingError: false
            };
        case TKTSYNCING_SCREENINGS_SUCCESS:
            return {
                ...state,
                isTktsyncing: false,
                hasTktsyncingError: false,
                imported: action.imported
            };
        case TKTSYNCING_SCREENINGS_FAILURE:
            return {
                ...state,
                isTktsyncing: false,
                hasTktsyncingError: true,
                tktsyncingErrorMessage: action.error.message
            };
        case UPDATING_SCREENING_PROPERTY:
            return {
                ...state,
                isLoading: true,
                hasLoadingError: false
            };
        case UPDATING_SCREENING_PROPERTY_SUCCESS:
            const screening = state.screening;
            switch (action.name) {
                case "title":
                case "description":
                case "pricings":
                case "note":
                case "cinema_hall":
                case "opaque":
                case "date":
                case "films":
                    screening[action.name] = action.screening[action.name]
            }
            // buckets
            if (_.isInteger(action.name))
                screening.buckets[action.name] = action.screening.buckets[action.name];
            else if (action.name === 'bucketlist_id')
                screening.buckets = action.screening.buckets;

            return {
                ...state,
                isLoading: false,
                hasLoadingError: false,
                screening: screening
            };
        case UPDATING_SCREENING_PROPERTY_FAILURE:
            return {
                ...state,
                isLoading: false,
                hasLoadingError: true,
                loadingErrorMessage: action.error.message
            };
        case UPDATING_SCREENING_PRICINGS:
            return {
                ...state,
                screening: {
                    ...state.screening,
                    pricings: action.pricings
                }
            };
        case UPDATING_SCREENING_PLACE:
            return {
                ...state,
                screening: {
                    ...state.screening,
                    cinema_hall: action.place
                }
            };
        case UPDATING_FILTERS:
            return {
                ...state,
                filters: action.filters,
                hasLoadingError: false
            };
        default:
            return state;
    }
};

// Actions
function loadingScreenings() { return { type: LOADING_SCREENINGS } }
function loadingScreeningsSuccess(screenings) { return { type: LOADING_SCREENINGS_SUCCESS, screenings: screenings } }
function loadingScreeningsFailure(err) { return { type: LOADING_SCREENINGS_FAILURE, error: err } }
export function loadScreenings(params) {
    params = params || {};
    params.places_ids = (params.places_ids || []).join(',');
    return (dispatch) => {
        dispatch(loadingScreenings());
        Screenings.list(params)
            .then(data => {
                const screenings = data.screenings;
                dispatch(loadingScreeningsSuccess(screenings));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(loadingScreeningsFailure(err))
            });
    }
}

function loadingScreening() { return { type: LOADING_SCREENING } }
function loadingScreeningSuccess(screening) { return { type: LOADING_SCREENING_SUCCESS, screening: screening } }
function loadingScreeningFailure(err) { return { type: LOADING_SCREENING_FAILURE, error: err } }
export function loadScreening(screeningId, fields) {
    if (screeningId)
        return (dispatch) => {
            dispatch(loadingScreening());
            Screenings.get(screeningId, fields)
                .then(data => {
                    const screening = data.screening;
                    dispatch(loadingScreeningSuccess(screening));
                })
                .catch(err => {
                    dispatch(loadingScreeningFailure(err))
                });
    }
}

function updatingScreening() { return { type: UPDATING_SCREENING } }
function updatingScreeningSuccess(screening) { return { type: UPDATING_SCREENING_SUCCESS, screening: screening } }
function updatingScreeningFailure(err) { return { type: UPDATING_SCREENING_FAILURE, error: err } }
export function updateScreening(screening) {
    return (dispatch) => {
        dispatch(updatingScreening());
        Screenings.update(screening)
            .then(data => {
                const screening = data.screening;
                dispatch(updatingScreeningSuccess(screening));
            })
            .catch(err => {
                dispatch(updatingScreeningFailure(err))
            });
    }
}

function creatingScreening() { return { type: CREATING_SCREENING } }
function creatingScreeningSuccess(screening) { return { type: CREATING_SCREENING_SUCCESS, screening: screening } }
function creatingScreeningFailure(err) { return { type: CREATING_SCREENING_FAILURE, error: err } }
export function startCreateScreening() { return { type: START_CREATING_SCREENING } }
export function createScreening(screening) {
    return (dispatch) => {
        dispatch(creatingScreening());
        Screenings.create(screening)
            .then(data => {
                const screening = data.screening;
                dispatch(creatingScreeningSuccess(screening));
                dispatch(loadingScreeningSuccess(screening));
            })
            .catch(err => {
                dispatch(creatingScreeningFailure(err))
            });
    }
}

function deletingScreening() { return { type: DELETING_SCREENING } }
function deletingScreeningSuccess() { return { type: DELETING_SCREENING_SUCCESS } }
function deletingScreeningFailure(err) { return { type: DELETING_SCREENING_FAILURE, error: err } }
export function deleteScreening(screeningId) {
    return (dispatch) => {
        dispatch(deletingScreening());
        Screenings.delete(screeningId)
            .then(data => {
                dispatch(deletingScreeningSuccess());
            })
            .catch(err => {
                dispatch(deletingScreeningFailure(err))
            });
    }
}

function addingPricing(names, currency) { return { type: ADDING_PRICING, names, currency } }
export function addPricing(names, currency) {
    return (dispatch) => {
        dispatch(addingPricing(names, currency));
    }
}

function removingPricing(_id) { return { type: REMOVING_PRICING, _id } }
export function removePricing(_id) {
    return (dispatch) => {
        dispatch(removingPricing(_id));
    }
}

function tktsyncingScreenings() { return { type: TKTSYNCING_SCREENINGS } }
function tktsyncingScreeningsSuccess(imported) { return { type: TKTSYNCING_SCREENINGS_SUCCESS, imported: imported } }
function tktsyncingScreeningsFailure(err) { return { type: TKTSYNCING_SCREENINGS_FAILURE, error: err } }
export function tktsyncScreenings() {
    return (dispatch) => {
        dispatch(tktsyncingScreenings());
        Screenings.tktsync()
            .then(data => {
                const imported = data.imported;
                dispatch(tktsyncingScreeningsSuccess(imported));
            })
            .catch(err => {
                console.log("err => ", err);
                dispatch(tktsyncingScreeningsFailure(err))
            });
    }
}

function updatingScreeningProperty() { return { type: UPDATING_SCREENING_PROPERTY } }
function updatingScreeningPropertySuccess(screening, name) { return { type: UPDATING_SCREENING_PROPERTY_SUCCESS, screening, name } }
function updatingScreeningPropertyFailure(err) { return { type: UPDATING_SCREENING_PROPERTY_FAILURE, error: err } }
export function updateScreeningProperty(payload, name) {
    return (dispatch) => {
        dispatch(updatingScreeningProperty());
        Screenings.updateScreeningProperty(payload)
            .then(data => {
                const screening = data.screening;
                dispatch(updatingScreeningPropertySuccess(screening, name));
            })
            .catch(err => {
                dispatch(updatingScreeningPropertyFailure(err))
            });
    }
}

function updatingScreeningPricings(pricings) { return { type: UPDATING_SCREENING_PRICINGS, pricings } }
export function updateScreeningPricings(pricings) {
    return (dispatch) => {
        dispatch(updatingScreeningPricings(pricings));
    }
}

function updatingScreeningPlace(place) { return { type: UPDATING_SCREENING_PLACE, place } }
export function updateScreeningPlace(place) {
    return (dispatch) => {
        dispatch(updatingScreeningPlace(place));
    }
}

function updatingFilters(filters) { return { type: UPDATING_FILTERS, filters } }
export function updateFilters(filters) {
    return (dispatch) => {
        dispatch(updatingFilters(filters));
    }
}
