import React from 'react';
import { useSelector } from 'react-redux';
import { Field } from 'redux-form'
import { useTranslation } from 'react-i18next';
import { Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap';
import { Badge, FormGroup, Label } from 'reactstrap';
import { Utils } from '../../../services';
import { EnvsField, SelectField, ShowCalculatorField, ToggleField, TwintMethodField } from '../../../components';
import { Eft } from '../../../models';
import PaymentMethodRuleForm from './PaymentMethodRuleForm/PaymentMethodRuleForm';
import TranslatableField from '../../TranslatableField/TranslatableField';

import './PaymentMethodForm.css';

const PaymentMethodForm = ({ name, method, methodIndex }) => {
    const { t }    = useTranslation();

    const { isMobile, isTablet } = useSelector(state => state.ui);
    const eftClosingOptions = [Eft.CLOSING_MODE_NEVER, Eft.CLOSING_MODE_YES, Eft.CLOSING_MODE_NO, Eft.CLOSING_MODE_ALWAYS].map(m => {
        return {
            key: m,
            text: t(`payment_methods.mode_${m}`),
            value: m
        };
    });

    return (
        <Row>
            <Col>
                <Card>
                    <CardBody>
                        {method._id && (
                            <Badge color="light" className="abs top-10 right-20">{ method._id }</Badge>
                        )}
                        <CardTitle className="small">{ t('settings.payment.method.general_informations') }</CardTitle>
                        <CardText tag="div">
                            <Row>
                                <Col>
                                    <FormGroup row className="align-items-end">
                                        <Label sm={12} lg={4} className={ !isMobile && !isTablet ? "text-right" : ""}>{t('settings.payment.method.name')}</Label>
                                        <Col sm={12} lg={8}>
                                            <TranslatableField required component="input" className="form-control" type="text" name={ `${name}.name` } id="name" placeholder={t("settings.payment.method.name_placeholder")} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row className="align-items-end">
                                        <Label sm={12} lg={4} className={ !isMobile && !isTablet ? "text-right" : ""}>{t('settings.payment.method.enabled')}</Label>
                                        <Col sm={12} lg={8}>
                                            <ToggleField name={ `${name}.enabled` } />
                                        </Col>
                                    </FormGroup>
                                    {!method._id && (
                                    <FormGroup row>
                                        <Label sm={12} lg={4} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.payment.method._id")}</Label>
                                        <Col sm={12} lg={7} data-tip data-for='tktIdTip'>
                                            <Field required component="input" className="form-control" type="text" name={ `${name}._id` } id="_id" autoComplete="_id" placeholder={t("settings.payment.method._id")} />
                                        </Col>
                                    </FormGroup>
                                    )}
                                    {method._id === Utils.PAYMENT_METHOD_ZVT_EFT && (
                                        <FormGroup row>
                                            <Label sm={12} lg={4} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("payment_methods.eft_receipt_printable_after_debit")}</Label>
                                            <Col sm={12} lg={8}>
                                                <ToggleField name={ `${name}.eft_receipt_printable_after_debit` } />
                                            </Col>

                                            <Label sm={12} lg={4} className={ !isMobile && !isTablet ? "text-right" : "" } >{t("payment_methods.closing_eft_mode")}</Label>
                                            <Col sm={12} lg={8} data-tip data-for='tktIdTip'>
                                                <SelectField name={ `${name}.auto_closing` } options={ eftClosingOptions } />
                                            </Col>
                                        </FormGroup>
                                    )}
                                    {method._id === Utils.PAYMENT_METHOD_CASH && (
                                        <FormGroup row>
                                            <Label sm={12} lg={4} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.payment.method.cash.show_calculator")}</Label>
                                            <Col sm={12} lg={8} data-tip data-for='tktIdTip'>
                                                <ShowCalculatorField name={ `${name}.show_calculator` } />
                                            </Col>
                                        </FormGroup>
                                    )}
                                    {method._id === Utils.PAYMENT_METHOD_SUMUP && (
                                        <FormGroup row>
                                            <Label sm={12} lg={4} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.payment.method.sumup.key")}</Label>
                                            <Col sm={12} lg={8} data-tip data-for='tktIdTip'>
                                                <Field component="input" className="form-control" type="text" name={ `${name}.key` } />
                                            </Col>
                                        </FormGroup>
                                    )}
                                    {method._id === Utils.PAYMENT_METHOD_TWINT && (
                                        <FormGroup row>
                                            <Label sm={12} lg={4} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.payment.method.twint.method")}</Label>
                                            <Col sm={12} lg={8} data-tip data-for='tktIdTip'>
                                                <TwintMethodField name={ `${name}.method` } />
                                            </Col>
                                        </FormGroup>
                                    )}
                                    {[Utils.PAYMENT_METHOD_PROXYPAY, Utils.PAYMENT_METHOD_PROXYPAY_ALT].includes(method._id) && (                                    <>
                                        {['url', 'sha_in', 'sha_out', 'api_key', 'seller'].map(key => (
                                            <FormGroup row>
                                                <Label sm={12} lg={4} className={ !isMobile && !isTablet ? "text-right" : ""} >{t(`settings.payment.method.proxypay.${key}`)}</Label>
                                                <Col sm={12} lg={8} data-tip data-for='tktIdTip'>
                                                    <Field component="input" className="form-control" type="text" name={ `${name}.${key}` } />
                                                </Col>
                                            </FormGroup>
                                        ))}
                                        <FormGroup row>
                                            <Label sm={12} lg={4} className={ !isMobile && !isTablet ? "text-right" : ""} >{t("settings.payment.method.proxypay.env")}</Label>
                                            <Col sm={12} lg={8} data-tip data-for='tktIdTip'>
                                                <EnvsField name={ `${name}.env` } />
                                            </Col>
                                        </FormGroup>
                                    </>
                                    )}
                                </Col>
                            </Row>
                        </CardText>
                        <CardTitle className="small">
                            { t('settings.payment.method.rules') }
                        </CardTitle>
                        <CardText tag="div">
                            { (method?.rules || []).map((rule, index) => (
                            <Card key={ index } className="mb-3">
                                <CardBody>
                                    <CardText tag="div">
                                        <PaymentMethodRuleForm name={ `${name}.rules[${index}]` } rule={ rule } method={ method.type } />
                                    </CardText>
                                </CardBody>
                            </Card>
                            ))}
                        </CardText>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default PaymentMethodForm;
