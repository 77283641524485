import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Button, Label } from 'reactstrap';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { BarChart } from '../../../components/Chart/';
import TableStats from '../../../components/TableStats/TableStats';
import StatsHeader from '../StatsHeader/StatsHeader'
import { loadStat, resetStat } from '../../../redux/modules/stats';
import { Utils, Filters } from '../../../services';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css'
import "./StatsMovies.css";
import _ from 'lodash';
import i18n from '../../../locales/i18n';
import moment from 'moment';
import SingleMovieTable from './SingleMovieTable';


const StatsMovies = ({ filters }) => {
    const { t }      = useTranslation();
    const dispatch   = useDispatch();
    const title      = t("statstypes.texts.movies");
    let dataGraphOne = [];
    let dataGraphTwo = [];
    let dataTable    = [];
    let subComponent = [];

    const { stats, stat }                     = useSelector(state => state.stats);
    const [showScreenings, setShowScreenings] = useState(true);
    const [expanded, setExpanded]             = useState(null);

    const data = stats?.movies

    const screeningsActions = !stat &&
        <Button
            onClick={() => {
                expandSub(!showScreenings);
                setShowScreenings(!showScreenings)
            }}
            color={showScreenings ? "danger" : "success"}
            className="noPrint"
        >
            {showScreenings ?
                <label className="mb-0"><i className='fa fa-filter'></i>&nbsp;{t("statstypes.hide_screenings")}</label> :
                <label className="mb-0"><i className='fa fa-filter'></i>&nbsp;{t("statstypes.show_screenings")}</label>
            }
        </Button>

    const getMovie = (movie, version) => {
        dispatch(loadStat('movie', {
            ...filters,
            movie,
            version
        }));
    }

    const hideSingle = () => {
        dispatch(resetStat());
    }

    const expandSub = (action) => {
        if (_.isBoolean(action)) {
            let temp = new Array(dataTable.length).fill(action);
            setExpanded(temp);
            return;
        }
        setExpanded(action);
    }

    const getExcelData = () => {
        if (dataTable && subComponent && columnsTableMovies) {
            let excelData = []
            let temp = []
            //Skip 1st and last index (buttons)
            for (let i = 1; i < columnsTableMovies.length - 2; i++) {
                temp.push(columnsTableMovies[i].Header)
            }
            temp.push(t("statstypes.chart.income"))
            excelData.push(temp)

            //Skip 1st and last index (buttons)
            for (let i = 0; i < dataTable.length; i++) {
                temp = [];
                temp.push(dataTable[i].title.title);
                temp.push(dataTable[i].room);
                temp.push(dataTable[i].version);
                temp.push(dataTable[i].week);
                temp.push(dataTable[i].screening);
                temp.push(dataTable[i].free);
                temp.push(dataTable[i].pass);
                temp.push(dataTable[i].pay);
                temp.push(dataTable[i].total);
                temp.push(dataTable[i].revenue);
                excelData.push(temp)
                if (expanded[i]) {
                    for (let y = 0; y < subComponent[i].length; y++) {
                        temp = []
                        temp.push(subComponent[i][y].title);
                        temp.push(subComponent[i][y].room);
                        temp.push(subComponent[i][y].version);
                        temp.push(subComponent[i][y].week);
                        temp.push(subComponent[i][y].screening);
                        temp.push(subComponent[i][y].free);
                        temp.push(subComponent[i][y].pass);
                        temp.push(subComponent[i][y].pay);
                        temp.push(subComponent[i][y].total);
                        temp.push(subComponent[i][y].revenue);
                        excelData.push(temp)
                    };
                };
            };

            return excelData
        }
    }

    if (!data || !data.films)
        return null;

    if (data.films && !stat) {
        const filmsData = data.films;
        dataTable = Object.keys(filmsData).map((film) => (
            {
                title: { title: filmsData[film]._id.title[i18n.language] ?? filmsData[film]._id.title.original, id: film },
                room: filmsData[film]._id.hall[0],
                version: filmsData[film]._id.version,
                week: filmsData[film]._id.week,
                screening: filmsData[film].screenings.length,
                screenings: filmsData[film].screenings,
                free: filmsData[film].stats.nbFree,
                pass: filmsData[film].stats.nbPass,
                pay: filmsData[film].stats.nbPaid,
                total: filmsData[film].stats.nbTotal,
                revenue: filmsData[film].stats.total,
                details: film
            }
        ));
        if (showScreenings) {
            subComponent = Object.keys(filmsData).map((film) => (
                filmsData[film].screenings.map(s => {
                    const key = s[film] ? film : Object.keys(s)[0];
                    return {
                        pad: "",
                        title: moment(s[key]._id.start_at).format("DD/MM/YYYY HH:mm"),
                        room: s[key]._id.hall[0],
                        version: s[key]._id.version,
                        week: "",
                        screening: "",
                        free: s[key].stats.nbFree,
                        pass: s[key].stats.nbPass,
                        pay: s[key].stats.nbPaid,
                        total: s[key].stats.nbTotal,
                        revenue: s[key].stats.total,
                        details: false
                    }
                })
            ))
        }
    }
    let showPass = false;
    if (data?.films) {
        Object.values(data.films).forEach(film => {
            showPass = showPass || (film.stats.nbPass > 0);
        });
    }

    if (data.films && !stat) {
        const tempArray = data.films
        dataGraphOne = _.compact(Object.keys(tempArray).map(film => {
            if (tempArray[film].stats.nbFree + tempArray[film].stats.nbPass + tempArray[film].stats.nbPaid > 0) {
                return {
                    id: tempArray[film]._id.title[i18n.language] ? tempArray[film]._id.title[i18n.language] : tempArray[film]._id.title.original,
                    [t("statstypes.chart.free_entries")]: tempArray[film].stats.nbFree,
                    [t("statstypes.chart.pass_entries")]: tempArray[film].stats.nbPass,
                    [t("statstypes.chart.paid_entries")]: tempArray[film].stats.nbPaid
                }
            }
        }));

        dataGraphTwo = _.compact(Object.keys(tempArray).map(film => {
            if (tempArray[film].stats.total > 0)
                return {
                    id: tempArray[film]._id.title[i18n.language] ? tempArray[film]._id.title[i18n.language] : tempArray[film]._id.title.original,
                    [t("statstypes.chart.income")]: tempArray[film].stats.total.toFixed(2)
                }
        }));
    }

    //Headers for movies table
    const columnsTableMovies = [
        {
            expander: true,
            Expander: ({ isExpanded }) => {
                return (
                    (isExpanded) ?
                        showScreenings && <i className='fa fa-minus block noPrint'></i> :
                        showScreenings && <i className='fa fa-plus block noPrint'></i>
                )
            },
            width: 35,
            filterable: false,
            className: 'align-center-stats noPrint expander',
            headerClassName: 'noPrint expander'
        },
        {
            Header: t("screenings.title"),
            Footer: cell => <b>{t("statstypes.total")}</b>,
            filterMethod: Filters.textFilterMethod(`title.title`),
            Filter: Filters.textFilter,
            Cell: row => (
                <div onClick={() => getMovie(data?.films[row.value.id]?._id?.title.original, data?.films[row.value.id]?._id?.version)}>{row.value.title}</div>
            ),
            sortMethod: (a, b, desc) => {
                a = a.title;
                b = b.title;

                // force any string values to lowercase
                a = typeof a === 'string' ? a.toLowerCase() : a
                b = typeof b === 'string' ? b.toLowerCase() : b
                // Return either 1 or -1 to indicate a sort priority
                if (a > b) {
                    return 1
                }
                if (a < b) {
                    return -1
                }
                // returning 0, undefined or any falsey value will use subsequent sorts or
                // the index as a tiebreaker
                return 0
            },
            filterAll: true,
            accessor: "title",
            className: "sticky printLarge bold-stats",
            headerClassName: "sticky printLarge",
        },
        {
            Header: t("statstypes.form.hall"),
            accessor: "room",
            filterable: false,
            className: 'printLarge',
            headerClassName: 'printLarge',
            Footer: "",
        },
        {
            Header: t("screenings.version_small"),
            accessor: "version",
            filterable: false,
            Footer: "",
            headerStyle: { textAlign: 'middle' },
            className: 'align-center-stats printSmall',
            headerClassName: "printSmall",
            width: 80
        },
        {
            Header: t("screenings.week_small"),
            accessor: "week",
            filterable: false,
            Footer: "",
            className: 'align-center-stats printSmall',
            headerClassName: "printSmall",
            width: 80
        },
        {
            Header: t("statstypes.screenings_small"),
            accessor: "screening",
            filterable: false,
            Cell: row => (
                _.isArray(row.value) ?
                    <p>{row?.value?.join(", ")}</p> :
                    <p>{row?.value}</p>
            ),
            Footer: "",
            className: 'align-center-stats printSmall',
            headerClassName: "printSmall",
            width: 80
        },
        {
            Header: t("statstypes.chart.paid_entries_small"),
            accessor: "pay",
            filterable: false,
            Footer: row => ((data?.total?.stats?.nbPaid || data?.total?.stats?.nbPaid === 0) ?
                <b>{data.total.stats.nbPaid}</b> : ""),
            className: 'align-center-stats printSmall',
            headerClassName: "printSmall",
            width: 80
        },
        {
            Header: t("statstypes.chart.free_entries_small"),
            accessor: "free",
            filterable: false,
            Footer: row => ((data?.total?.stats?.nbFree || data?.total?.stats?.nbFree === 0) ?
                <b>{data.total.stats.nbFree}</b> : ""),
            className: 'align-center-stats printSmall',
            headerClassName: "printSmall",
            width: 80
        },
        (showPass ? {
            Header: t("statstypes.chart.pass_entries_small"),
            accessor: "pass",
            filterable: false,
            Footer: row => ((data?.total?.stats?.nbPass || data?.total?.stats?.nbPass === 0) ?
                <b>{data.total.stats.nbPass}</b> : ""),
            className: 'align-center-stats printSmall',
            headerClassName: "printSmall",
            width: 80
        } : null),
        {
            Header: t("statstypes.total"),
            accessor: "total",
            filterable: false,
            Footer: row => ((data?.total?.stats?.nbTotal || data?.total?.stats?.nbTotal === 0) ?
                <b>{data.total.stats.nbTotal}</b> : ""),
            className: 'align-center-stats printSmall',
            headerClassName: "printSmall",
            width: 80
        },
        {
            Header: <>{t("statstypes.chart.income")}&nbsp;<Label className="expander">{Utils.currency()}&nbsp;<i className="fa fa-sort noPrint"></i></Label></>,
            accessor: "revenue",
            filterable: false,
            Cell: row => (
                row.value.toFixed(2)
            ),
            Footer: row => ((data?.total?.stats?.total || data?.total?.stats?.total === 0) ?
                <b>{data.total.stats.total.toFixed(2)}</b> : ""),
            className: 'align-center-stats printSmall',
            headerClassName: "printSmall",
            width: 120
        },
        {
            accessor: "details",
            width: 35,
            filterable: false,
            Cell: row => (  //If showCharts is true, we want to show info and print buttons
                row.value &&
                <>
                    <Button size='sm' color='success' onClick={() => getMovie(data?.films[row.value]?._id?.title.original, data?.films[row.value]?._id?.version)}><i className='fa fa-search-plus'></i></Button>
                </>
            ),
            className: "noPrint expander",
            Footer: "",
            headerClassName: 'noPrint expander'
        },
    ].filter(v => !!v);

    //Headers for sub-movies table
    const columnsSub = [
        {
            filterable: false,
            accessor: "pad",
            width: 35,
            className: "pad",
            headerClassName: "",
            className: "noPrint expander",
        },
        {
            filterable: false,
            accessor: "title",
            className: "align-center-stats sticky printLarge",
            headerClassName: 'printLarge',
        },
        {
            accessor: "room",
            filterable: false,
            className: 'printLarge',
            headerClassName: 'printLarge'
        },
        {
            accessor: "version",
            filterable: false,
            className: 'align-center-stats printSmall',
            headerClassName: 'printSmall',
            width: 80
        },
        {
            accessor: "week",
            filterable: false,
            className: 'align-center-stats printSmall',
            headerClassName: 'printSmall',
            width: 80
        },
        {
            accessor: "screening",
            filterable: false,
            className: 'align-center-stats printSmall',
            headerClassName: 'printSmall',
            width: 80
        },
        {
            accessor: "pay",
            filterable: false,
            className: 'align-center-stats printSmall',
            headerClassName: 'printSmall',
            width: 80
        },
        {
            filterable: false,
            accessor: "free",
            className: 'align-center-stats printSmall',
            headerClassName: 'printSmall',
            width: 80
        },
        (showPass ? {
            accessor: "pass",
            filterable: false,
            className: 'align-center-stats printSmall',
            headerClassName: 'printSmall',
            width: 80
        } : null),
        {
            accessor: "total",
            filterable: false,
            className: 'align-center-stats printSmall ',
            headerClassName: 'printSmall ',
            width: 80
        },
        {
            accessor: "revenue",
            filterable: false,
            Cell: row => (
                row.value.toFixed(2)
            ),
            className: 'align-center-stats printSmall',
            headerClassName: 'printSmall',
            width: 120
        },
        {
            accessor: "details",
            width: 35,
            filterable: false,
            className: "noPrint expander",
            headerClassName: "noPrint",
        },
    ].filter(v => !!v);

    return (
        <div className='StatsMovies'>
            {/* Page title and back button if on print single movie */}
            <Row>
                <Col className="noPrint">
                    <PageTitle
                        icon="film"
                        title={title}
                    />
                </Col>
            </Row>


            {
                stat &&
                    <SingleMovieTable filters={filters} />
            }

            {!stat &&
                <>
                    <StatsHeader
                        filters={filters}
                        title={
                            <PageTitle
                                icon="film"
                                title={title}
                            />
                        }
                    />
                    <div>
                        {(dataTable) &&
                            <TableStats
                                id="moviesStatsTable"
                                className={`printStatsMovies ${showPass ? 'withPass' : 'withoutPass'} ${showScreenings ? 'withSubTable' : ''}`}
                                data={dataTable}
                                getExcelData={getExcelData}
                                expanded={expanded ? expanded : expandSub(true)}
                                onExpandedChange={(event) => expandSub(event)}
                                showPagination={true}
                                columns={columnsTableMovies}
                                print
                                customTitle={screeningsActions}
                                SubComponent={(row) => {
                                    return (
                                        (showScreenings && subComponent) &&
                                        <ReactTable
                                            className='subTable'
                                            TheadComponent={props => null}
                                            data={subComponent[row.index]}
                                            showPagination={false}
                                            columns={columnsSub}
                                            pageSize={subComponent[row.index].length}
                                        />
                                    )
                                }}
                            />
                        }

                        {/* If showStats state is true and stats for graphs are set */}
                        {dataGraphOne.length > 0 && dataGraphTwo.length > 0 &&
                            <Row className="noPrint">
                                <Col sm={12} lg={6} style={{ height: "800px" }}>
                                    <BarChart
                                        data={dataGraphOne}
                                        keys={[t("statstypes.chart.free_entries"), t("statstypes.chart.pass_entries"), t("statstypes.chart.paid_entries")]}
                                        axisLeftLegend=""
                                        axisBottomLegend=""
                                        axisBottomTickRotation={35}
                                        tooltipFormat="$"
                                        enableLabel={false}
                                    />
                                </Col>
                                <Col sm={12} lg={6} style={{ height: "400px" }}>
                                    <BarChart
                                        data={dataGraphTwo}
                                        keys={[t("statstypes.chart.income")]}
                                        axisLeftLegend=""
                                        axisBottomLegend=""
                                        axisBottomTickRotation={35}
                                        tooltipFormat="$"
                                        enableLabel={false}
                                    />
                                </Col>
                            </Row>}
                    </div>
                </>}
        </div>
    )
}

export default StatsMovies;
