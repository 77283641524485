import React from "react";
import { Button } from 'reactstrap';

const OverviewSeat = ({
    item,
    children,
    draggedCells,
    index,
    color,
    onClick,
    inZone
}) => {

    item = item || {};

    const isInDragSelection = draggedCells.findIndex((e) => e === index) > -1;

    const borderColor = item.borderColor !== undefined && item.borderColor !== '' ? item.borderColor : (item.status === 'booked' ? '#994545' : '#454545');

    return (
        <Button
            style={{
                position: 'relative',
                border: "1px solid #454545",
                borderColor: borderColor,
                backgroundColor: isInDragSelection ? "blue" : (color ?? 'transparent'),
                color: 'black',
                width: inZone ? 30 : 20,
                height:  inZone ? 30 : 20,
            }}
            key={ index }
            variant="flat"
            onClick={ onClick }
        >
            {(item.status === 'booked') && (
                <div className="booked dot" />
            )}
            { children }
        </Button>
    );
};

export default OverviewSeat;
