import React from "react";
import { Button } from 'reactstrap';

const ZoneSeat = ({  onClick, style, id, children, seatType}) => {

   var seatTypeSVGUrl = `url(https://fiff.ticketack.com/img/seats/${seatType}.svg)`

    return (
        <Button
            id={ id }
            className="seat"
            style={{
                width: 30,
                height: 30,
                ...style
            }}
            key={ id }
            variant="flat"
            onClick={ () => onClick && onClick() }
        >
            <div style = {{
                 left: 2,
                 width: 24,
                 height: 24,
                 backgroundImage: `${seatTypeSVGUrl}`,
                 backgroundPosition: 'center',
                 backgroundSize: 'cover',
                 backgroundRepeat: 'no-repeat',
                 position: 'relative'
            }}>
                { children }

            </div>
        </Button>
    )
};

export default ZoneSeat;
