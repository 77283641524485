import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { setActiveTab } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';

import './ItemsList.css';

const ItemsList = ({ title, items, onSelect, onEdit, horizontal, storageKey, selectDefault }) => {
    const [selected, select] = useState({
        selection: items?.length && items[0].selection,
        index: 0
    });

    const { tabs }               = useSelector(state => state.userSettings.KUserSettings);
    const { isMobile, isTablet } = useSelector(state => state.ui);

    const dispatch = useDispatch();

    const activeTab = (tabs || {})[storageKey];

    useEffect(() => {
        if (storageKey && activeTab) {
            const index = items?.findIndex(item => item.selection === activeTab);
            if (index) {
                select({
                    selection: activeTab,
                    index
                });
                onSelect && onSelect(activeTab, index);
            }
        }
    }, [storageKey, activeTab]);

    if (!items?.length)
        return null;

    const selectItem = (key, index) => {
        if (storageKey)
            dispatch(setActiveTab(storageKey, key));

        onSelect && onSelect(key, index);
    };

    if (selectDefault) {
        const hasActive = items.findIndex(i => !!i.active);
        if (!(hasActive >= 0)) {
            items[0].active = true;
            selectItem(items[0].selection, 0);
        }
    }

    const renderItem = (i, index) => {
        if (isMobile || isTablet)
            return (
                <option
                    key={i.key}
                    value={index}
                >
                    {i.title}
                </option>
            );
        if (!i.isButton)
            return (
                <div
                    key={i.key}
                    className={`list-item depth-${i.depth} ${i.active ? 'active' : ''}`}
                    onClick={() => { selectItem(i.selection, index) }}
                >
                    {i.icon && <i className={`fa fa-${i.icon}`}></i>}

                    {i.image && (
                            <img src={i.image} className="img rounded" />
                    )}

                    {i.title}

                    {onEdit && i.selection && (
                        <Button color="outline" size="xs" onClick={() => { onEdit(i.selection, index) }} >
                            <i className={`fa fa-pencil`}></i>
                        </Button>
                    )}
                </div>
            );
        return (
            <div key={i.key} className={`list-item depth-${i.depth}`} >
                <Button color="info" size="sm" block onClick={() => { selectItem(i.selection, index) }} >
                    <i className={`fa fa-${i.icon}`}></i>
                    {i.title}
                </Button>
            </div>
        );
    };

    const renderEditButtonResponsive = (selection, index) => {
        return (
            <Button color="primary" size="xs" onClick={() => { onEdit(selection, index) }} >
                <i className={`fa fa-pencil`}></i>
            </Button>
        );
    }

    return (
        <div className={`ItemsList ${horizontal ? 'horizontal' : ''}`}>
            <div className="list-wrapper">
                {title && (
                    <div className="list-title">
                        {title}
                    </div>
                )}
                {isMobile || isTablet ?
                    <div className={`d-flex responsive-itemslist${onEdit ? " editable" : ""}`}>
                        <select
                            className={`form-control`}
                            onChange={(e) => {
                                const index = e.target.value;
                                const selection = items[index].selection;
                                select({selection: selection, index: index})
                                selectItem(selection, index)
                            }}
                        >
                            {items.map((c, i) => renderItem(c, i))}
                        </select>
                        {onEdit &&
                            renderEditButtonResponsive(selected.selection, selected.index)
                        }
                    </div>
                    :
                    <>
                        {items.map((c, i) => renderItem(c, i))}
                    </>
                }
            </div>
        </div>
    );
}

export default ItemsList;
