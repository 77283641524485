import React, { useEffect, useMemo, useState } from 'react';
import { Field } from 'redux-form';
import ScreeningFormGeneralField from '../ScreeningFormGeneralField/ScreeningFormGeneralField';
import PlaceMap from '../../../Places/PlaceMap/PlaceMap';
import { Button, ButtonGroup, Col, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { updateScreeningProperty, updateScreeningPlace } from '../../../../redux';

const ScreeningFormMap = ({ isLoading, places, placeId }) => {
    const { t }                           = useTranslation();
    const dispatch                        = useDispatch();
    const [currentPlace, setCurrentPlace] = useState(placeId);
    const [key, setKey]                   = useState(0);
    const { screening }                   = useSelector(state => state.screenings);

    const updateMap = (values) => {
        let payload = {
            _id: values._id,
            cinema_hall: values.cinema_hall
        };
        dispatch(updateScreeningProperty(payload, "cinema_hall"));
    }

    const updatePlace = () => {
        dispatch(updateScreeningPlace(places.find(p => p._id === currentPlace)))
    }

    useEffect(() => {
        //Hack to refresh map component on screening map change
        setKey(key + 1);
    }, [screening.cinema_hall])

    return (
        <div className='ScreeningFormMap'>
            <ScreeningFormGeneralField onSubmit={(values) => updateMap(values)}>
                <Row>
                    <Col sm={2}>
                        <h3 className="border-bottom border-dark">{t('places.place')}</h3>
                            <Field component="select" className='form-control' name="cinema_hall._id" onChange={(e) => setCurrentPlace(e.target.value)}>
                                {places.map(p =>
                                    <option key={p._id} value={p._id} >
                                        {p.name}
                                    </option>
                                )}
                            </Field>
                            <Button type='button' className="float-right mt-1" color="success" onClick={updatePlace}>
                                <i className='fa fa-exchange'></i>&nbsp;{t("screenings.edit.form.maps.change_map")}
                            </Button>
                    </Col>
                    <Col sm={10}>
                        {screening?.cinema_hall.map &&
                            <Field component={PlaceMap} key={key} name="cinema_hall.map" screeningSeats={screening?.cinema_hall?.map?.seats || []} />
                        }
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <ButtonGroup>
                            <Button type="submit" color="success" disabled={isLoading}>
                                {isLoading ?
                                    <span>
                                        &nbsp;<i className="fa fa-spinner fa-spin"></i>
                                    </span>
                                    :
                                    <span>
                                        <i className="fa fa-save"></i>&nbsp;
                                        {t("common.save")}
                                    </span>
                                }
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>
            </ScreeningFormGeneralField>
        </div>
    )
}

export default ScreeningFormMap;
