import React, { useEffect, useState } from 'react';
import { useSelector, connect } from 'react-redux'
import { Form } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ItemsList } from '../../../components';
import { reduxForm } from 'redux-form'
import _ from 'lodash'
import { ScreeningFormBuckets, ScreeningFormInfo, ScreeningFormLogs, ScreeningFormPricings, ScreeningFormBookings, ScreeningFormMap } from './ScreeningFormComponents';

import './ScreeningForm.css';

let ScreeningForm = ({ places, setting, tickets, logs, isLoading, handleSubmit, isCreation }) => {
    const { t }         = useTranslation();
    const [tab, setTab] = useState('general');
    const { screening } = useSelector(state => state.screenings);

    const onSelect = (tab) => {
        setTab(tab);
    }

    const itemList = React.useMemo(() => [
        {
            active: (tab === 'general') ? true : false,
            depth: 0,
            icon: "address-card",
            key: "general",
            selection: 'general',
            title: t("screenings.edit.itemlist.general")
        }, {
            active: (tab === 'bucket') ? true : false,
            depth: 0,
            icon: "bookmark",
            key: "bucket",
            selection: 'bucket',
            title: t("screenings.edit.itemlist.bucket")
        }, {
            active: (tab === 'pricing') ? true : false,
            depth: 0,
            icon: "money",
            key: "pricing",
            selection: 'pricing',
            title: t("screenings.edit.itemlist.pricing")
        }, {
            active: (tab === 'booking') ? true : false,
            depth: 0,
            icon: "ticket",
            key: "booking",
            selection: 'booking',
            title: t("screenings.edit.itemlist.booking")
        }, {
            active: (tab === 'map') ? true : false,
            depth: 0,
            icon: "map",
            key: "map",
            selection: 'map',
            title: t("places.place")
        }, {
            active: (tab === 'history') ? true : false,
            depth: 0,
            icon: "history",
            key: "history",
            selection: 'history',
            title: t("screenings.edit.itemlist.history")
        }
    ], [tab]);

    if (isCreation)
        return (
            <div className="ScreeningForm w-100">
                <Form onSubmit={handleSubmit}>
                    <ScreeningFormInfo isCreation={isCreation} />
                </Form>
            </div>
        );

    return (
        <div className="ScreeningForm w-100">
            <ItemsList
                horizontal
                items={itemList}
                onSelect={onSelect}
                storageKey="screening"
            />
            <Form onSubmit={handleSubmit}>
                { tab === 'general' &&
                    <ScreeningFormInfo isLoading={isLoading} />
                }
            </Form>
            { tab === 'bucket' &&
                <ScreeningFormBuckets buckets={screening.buckets} isLoading={isLoading} />
            }
            { tab === 'pricing' &&
                <ScreeningFormPricings pricings={screening.pricings} isLoading={isLoading} />
            }
            { tab === 'booking' &&
                <ScreeningFormBookings tickets={tickets} screeningId={screening._id} setting={setting} />
            }
            { tab === 'map' &&
                <ScreeningFormMap isLoading={isLoading} placeId={screening.cinema_hall._id} places={places.places} />
            }
            { tab === 'history' &&
                <ScreeningFormLogs logs={logs} setting={setting} />
            }
        </div >
    );
}

const mapDispatchToProps = {};

// Decorate with reduxForm(). It will read the initialValues prop provided by connect()
ScreeningForm = reduxForm({
    form: "ScreeningForm",
    enableReinitialize: true
})(ScreeningForm)

// You have to connect() to any reducers that you wish to connect to yourself
ScreeningForm = connect(
    state => ({
        initialValues: state.screenings.screening,
        screening: state.screenings.screening,
        places: state.places,
        destroyOnUnmount: false
    }), mapDispatchToProps
)(ScreeningForm)

export default ScreeningForm;
